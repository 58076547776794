/* stylelint-disable max-line-length */

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --ant-table-cell-height: 65px;
  --ant-table-cell-editing-border-width: 2px;
  --ant-table-cell-editing-padding: 16px;
}

@layer base {
  ::-webkit-scrollbar {
    @apply w-3 h-3;
  }

  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-track {
    @apply bg-transparent dark:bg-gray-80;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gray-20 dark:bg-gray-60 rounded-lg;
  }

  * {
    /* W3C standard just for Firefox */
    scrollbar-width: thin;
  }

  html {
    tab-size: 2;
  }

  body {
    overflow-x: hidden
  }

  html,
  body,
  #root {
    @apply h-full;

    font-size: 16px;
  }
}

@layer components {
  .files-grid {
    display: grid;
    grid-template-columns: 25px 14% 13% 15% 12% 12%;
    gap: 0 0;
    grid-auto-flow: column;
    align-items: center;
    justify-items: start;
  }

  .bg-icons-gradient {
    background:
      linear-gradient(
        90deg,
        color-mix(in srgb, rgb(var(--color-white-rgb)) 50%, transparent 50%) 0%,
        color-mix(in srgb, rgb(var(--color-white-rgb)) 50%, rgb(var(--color-white-rgb)) 50%) 20px,
        color-mix(in srgb, rgb(var(--color-white-rgb)) 50%, rgb(var(--color-white-rgb)) 50%) 100%
      );
  }

  .dark .bg-icons-gradient {
    background:
      linear-gradient(
        90deg,
        color-mix(in srgb, rgb(var(--color-gray-70-rgb)), transparent) 0%,
        color-mix(in srgb, rgb(var(--color-gray-70-rgb)), rgb(var(--color-gray-70-rgb)) 100%) 20px,
        color-mix(in srgb, rgb(var(--color-gray-70-rgb)), rgb(var(--color-gray-70-rgb)) 100%) 100%
      );
  }

  .sorter-caret {
    @apply w-[11px] h-[11px] text-gray-30 dark:text-gray-60;
  }

  .sorter-caret.active {
    @apply text-primary;
  }

  .file-card {
    @apply bg-white hover:shadow-file-card dark:bg-gray-80 dark:hover:bg-gray-70 rounded-lg p-6 mb-5 last:mb-0 text-sm border border-transparent;
  }

  .file-card.checked {
    @apply !border-primary;
  }

  .file-card:hover .file-card-icon {
    @apply visible;
  }

  .file-card-icon {
    @apply ml-6 cursor-pointer text-gray-40 hover:text-primary w-[20px] h-[20px] text-[22px] invisible;
  }

  .file-card-icon.disabled {
    @apply pointer-events-none text-gray-60;
  }

  .background-task-grid {
    display: grid;
    grid-template-columns: 20px 115px auto 1fr 20px;
    align-items: center;
  }

  .early-adopter-card-content {
    column-width: 320px;
    width: 690px;
  }
}

@layer utilities {
  .flex-col-grow {
    @apply flex-grow flex flex-col;
  }

  .shadow-file-card {
    box-shadow: 0 6px 12px rgb(0 0 0 / 0.08);
  }

  .animate-fade-in-out {
    opacity: 0;
    animation: fade-in-out 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .animate-fade-in {
    opacity: 0;
    animation: fade-in 0.25s cubic-bezier(0.4, 0, 0.6, 1) forwards;
  }

  @keyframes fade-in-out {
    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.ant-modal-wrap.ketcher .ant-modal-footer,
body {
  @apply bg-white text-gray-70;
}

.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  @apply dark:bg-transparent border-primary;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  @apply text-gray-70 border-primary dark:text-gray-5;
}

.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover {
  @apply bg-white border-primary;
}

.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn-ghost,
.ant-btn-ghost:hover {
  @apply text-gray-70;
}

.ant-radio-inner,
.ant-btn.ant-btn-background-ghost,
.ant-btn-ghost {
  @apply border-gray-30;
}

.ant-btn.ant-btn-background-ghost:hover,
.ant-btn-ghost:hover {
  @apply border-primary-100 dark:border-primary;
}

.ant-btn.ant-btn-background-ghost[disabled],
.ant-btn.ant-btn-background-ghost[disabled]:hover,
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover {
  @apply bg-gray-10 text-gray-30 border-gray-20 dark:bg-gray-80 dark:text-gray-50 dark:border-gray-65;
}

.ant-modal-title,
.ant-modal-close,
.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm-body .ant-modal-confirm-content,
.ant-modal-content,
.ant-radio-wrapper,
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner,
.ant-btn.ant-btn-background-ghost,
.ant-select-item,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-checkbox-wrapper,
.ant-table-cell-fix-left,
.ant-table-cell-fix-right,
.ant-table-tbody,
.ant-upload,
.ant-btn-text,
.ant-btn-ghost,
.ant-table-thead > tr > th,
.ant-popover-inner-content,
.ant-popover-message,
.ant-notification,
.ant-notification-notice-message,
.ant-notification-notice-close,
.ant-popover-title,
.ant-form-item-explain:not(.ant-form-item-explain-error),
.ant-form-item-explain-success,
.ant-message-notice-content,
.ant-drawer-title,
.ant-tabs-tab,
.ant-tabs,
.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-title,
body {
  @apply dark:text-white;
}

.ant-radio-inner,
.ant-radio-disabled .ant-radio-inner {
  @apply bg-transparent;
}

.ant-radio-disabled ~ span {
  @apply text-gray-30 dark:text-gray-50;
}

.ant-radio-wrapper {
  @apply !text-base;
}

.ant-modal-confirm-content {
  @apply !ml-0 !text-base;
}

.ant-table .ant-table-body tr td.ant-table-cell {
  height: var(--ant-table-cell-height);
}

.ant-table .ant-table-body tr td.ant-table-cell.editing {
  @apply border-2 border-primary p-0;

  border-width: var(--ant-table-cell-editing-border-width);
}

.ant-table .ant-table-body tr td.ant-table-cell.editing > input {
  @apply dark:bg-gray-50;

  padding: var(--ant-table-cell-editing-padding);
  min-height: calc(var(--ant-table-cell-height) - 2 * var(--ant-table-cell-editing-border-width));
  border-radius: 0;
}

.ant-table,
td.ant-table-cell {
  line-height: 1.2
}

/* Selector */
.dark .ant-table-thead > tr > th {
  @apply bg-gray-80;

  width: 50%;
}

.ant-modal-close-x {
  margin-top: 6px;
}

/* Selector */
.ant-table-thead > tr > th {
  @apply dark:border-gray-80;

  width: 50%;
  font-weight: 600;
  font-size: 16px;
}

/* Selector */
.dark .ant-table-tbody > tr > td {
  --tw-border-opacity: 1;

  @apply !border-gray-60;

  background-color: rgba(57 62 72 / 1) !important;
}

/* Selector */
.ant-table-tbody > tr > td {
  --tw-border-opacity: 1;

  @apply dark:border-gray-70;

  background-color: white !important;
}

/* Selector */

.ant-switch-handle {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px 16px;
}

/* Selector */
.custom-menu-item {
  @apply border-b border-b-gray-50 border-solid;
}

/* Selector */
.ant-switch-checked:focus:hover,
.ant-switch-checked:focus,
.ant-switch-checked {
  @apply !border !border-solid !border-gray-20;

  box-shadow: none;
}

.ant-switch-inner {
  @apply border border-gray-20 dark:border-none;
}

.ant-switch .ant-switch-inner .ant-switch-inner-checked {
  margin-inline-start: -40px;
}

.ant-switch.ant-switch-checked .ant-switch-inner {
  padding-inline-start: 22px;
  padding-top: 1px;
}

.ant-switch .ant-switch-inner {
  padding-inline-start: 3px;
}

.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
  margin-top: -14px;
}

/* Selector */
.plans .ant-tabs-nav {
  align-items: center;
  justify-content: center;
  width: 1040px;
  margin-top: 28px !important;
}

/* Selector */
.dark .plans .ant-tabs-nav::before {
  display: none;
}

.plans .ant-tabs-tab {
  padding: 10px 35px;
  margin: 0 !important;
  color: red;
}

.plans .ant-tabs-tab.ant-tabs-tab:first-child {
  @apply border-l-gray-50 border-l border-solid;
  @apply border-b-gray-50 border-b border-solid;
  @apply border-t-gray-50 border-t border-solid;

  border-top-left-radius: 4px; /* Adjust the radius value as needed */
  border-bottom-left-radius: 4px; /* Adjust the radius value as needed */
}

.plans .ant-tabs-tab.ant-tabs-tab:not(:first-child) {
  @apply border-r-gray-50 border-r border-solid;
  @apply border-b-gray-50 border-b border-solid;
  @apply border-t-gray-50 border-t border-solid;

  border-top-right-radius: 4px; /* Adjust the radius value as needed */
  border-bottom-right-radius: 4px; /* Adjust the radius value as needed */
}

.plans .ant-tabs-tab.ant-tabs-tab-active:first-child {
  @apply bg-primary;
  @apply border-primary;

  border-top-left-radius: 4px; /* Adjust the radius value as needed */
  border-bottom-left-radius: 4px; /* Adjust the radius value as needed */
}

/* Apply right border radius to the last active tab */
.plans .ant-tabs-tab.ant-tabs-tab-active:not(:first-child) {
  @apply bg-primary;
  @apply border-primary;

  border-top-right-radius: 4px; /* Adjust the radius value as needed */
  border-bottom-right-radius: 4px; /* Adjust the radius value as needed */
}

.plans .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.plans .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.plans .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.plans .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  display: none;
}

.plans .ant-tabs-top > .ant-tabs-nav::before,
.plans .ant-tabs-top > div > .ant-tabs-nav::before {
  display: none;
}

.plans .ant-tabs-nav-wrap {
  flex: none !important;
}

.dark .plans .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.dark .plans .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: white !important;
}

.plans .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.plans .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  @apply !text-gray-70;
}

.ant-table-body,
.ant-table-tbody > tr.ant-table-placeholder:hover > td,
.ant-table-cell-fix-left,
.ant-table-cell-fix-right,
.ant-table-tbody,
.ant-table-header,
.ant-upload.ant-upload-drag,
body {
  @apply dark:bg-gray-100;
}

.ant-select-arrow {
  @apply dark:text-gray-10;
}

.ant-modal-header,
.ant-drawer-content,
.ant-drawer-header,
.ant-drawer-body,
.ant-modal-content,
.ant-popover-arrow-content,
.ant-message-notice-content,
.ant-notification-notice,
.ant-popover-inner {
  @apply dark:bg-gray-70;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  @apply dark:bg-gray-80;
}

.dark .ant-empty-img-simple-path {
  @apply fill-current text-gray-100;
}

.dark .ant-empty-img-simple-ellipse {
  @apply fill-current text-gray-80;
}

.ant-btn-text,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  @apply transition-none;
  @apply dark:border-gray-70;
}

.ant-table-small .ant-table-thead > tr > th {
  @apply dark:bg-gray-80;
}

.ant-notification-notice-error .ant-notification-notice-icon > .anticon,
.ant-notification-notice-warning .ant-notification-notice-icon > .anticon {
  @apply text-danger;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  @apply text-danger mr-2;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  @apply text-warning mr-2;
}

.ant-modal-confirm .ant-modal-body {
  @apply p-6;
}

.ant-switch:not(.ant-switch-checked),
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  @apply dark:bg-gray-50;
}

.ant-radio-disabled .ant-radio-inner,
.ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner {
  @apply !border-gray-30 dark:!border-gray-50;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  @apply bg-gray-30 dark:bg-gray-60;
}

.ant-checkbox-disabled + span {
  color: inherit;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  @apply border-gray-20 dark:border-gray-40;
}

.ant-tabs-nav::before,
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  @apply !border-gray-30 dark:!border-gray-60;
}

.tab-center .ant-tabs-nav-list {
  @apply w-full justify-around;
}

.tab-center .ant-tabs-nav-list .ant-tabs-tab {
  @apply justify-self-stretch w-full flex justify-center;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  @apply py-3;
}

.ant-form-item-label > label::after {
  content: unset;
}

.items-center .anticon,
.items-center svg.sdf-icon {
  @apply -mt-0.5;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  @apply text-gray-40 bg-gray-20 dark:text-gray-50 dark:border-gray-65 dark:bg-gray-65;
}

.ant-btn-text[disabled] svg {
  @apply text-gray-20;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled] svg,
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  @apply dark:text-gray-65;
}

.ant-image-preview-operations {
  @apply bg-transparent dark:text-white;
}

.ant-image-preview-operations-operation-disabled {
  @apply text-gray-30 dark:text-gray-40;
}

.ant-input-number-handler-wrap,
.ant-select-dropdown,
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-dropdown-menu {
  @apply dark:bg-gray-60;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
td.ant-table-column-sort {
  @apply dark:bg-gray-90;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  @apply dark:bg-primary-300 dark:border-gray-100;
}

.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input,
.ant-input-affix-wrapper {
  @apply dark:bg-gray-60 dark:text-white;
}

.ant-btn-ghost,
.ant-btn.ant-btn-background-ghost,
.ant-upload.ant-upload-drag {
  @apply dark:border-gray-50;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-form-item:not(.ant-form-item-has-error) .ant-input-affix-wrapper,
.ant-form-item:not(.ant-form-item-has-error) .ant-input {
  @apply dark:border-gray-60;
}

/* fix chrome autofill bg issue, set bg-gray-60 color */
/* stylelint-disable property-no-vendor-prefix */
.dark .ant-input:-webkit-autofill[type="password"] {
  border-radius: 0;
}

.dark .ant-input:-webkit-autofill,
.dark .ant-input:-webkit-autofill:hover,
.dark .ant-input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: inset 0 0 0 50px #565962 !important;
  background-color: #565962;
}
/* stylelint-enable property-no-vendor-prefix */

.ant-tabs-tab-btn,
.ant-input::placeholder {
  @apply text-gray-40 dark:text-gray-30;
}

.ant-input-password .anticon-eye,
.ant-input-password .anticon-eye:hover {
  @apply text-primary;
}

.ant-input-password .anticon-eye-invisible {
  @apply text-gray-30 dark:text-gray-40;
}

.ant-input-password .anticon-eye-invisible:hover {
  @apply text-gray-40 dark:text-gray-30;
}

.ant-input-affix-wrapper-disabled .anticon-eye,
.ant-input-affix-wrapper-disabled .anticon-eye:hover,
.ant-input-affix-wrapper-disabled .anticon-eye-invisible,
.ant-input-affix-wrapper-disabled .anticon-eye-invisible:hover {
  @apply dark:text-gray-50;
}

.ant-image-preview-img {
  display: inline;
  height: 80%;
}

.ant-image-preview-mask {
  @apply bg-white dark:bg-gray-100;
}

.ant-image-mask-info {
  font-size: 0;
}

.ant-image-mask-info .anticon {
  font-size: 16px;
  margin-inline-end: 0;
}

.ant-modal-wrap {
  @apply pt-6;
}

.ant-modal-header,
.ant-popover-title,
.ant-modal-footer {
  @apply border-0;
}

.ant-modal-confirm-title,
.ant-modal-title {
  @apply !font-bold;
}

.ant-modal-header {
  @apply px-6 pt-6 pb-0;
}

.ant-modal-body {
  @apply p-6;
}

.ant-modal-footer {
  @apply px-6 pt-0 pb-6;
}

.ant-drawer-title {
  @apply font-bold text-xl;
}

.ant-drawer-header {
  @apply py-6 px-6 -mb-6;
}

.ant-message-custom-content {
  @apply flex;
}

.ant-form-item-explain {
  @apply text-xs;
}

.ant-progress-inner {
  @apply overflow-visible;
}

.ant-progress-bg {
  box-shadow: 0 0 8px rgb(86 201 153 / 0.6);
}

.ant-progress .ant-progress-bg {
  background-color: #56c999;
}

.ant-input-suffix {
  margin: 0
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer;
}

.upload-zone,
.upload-zone > span,
.upload-zone .ant-upload-drag {
  @apply flex-col-grow;
}

.upload-zone .ant-upload.ant-upload-disabled {
  @apply cursor-auto py-0;
}

.upload-zone .ant-upload-disabled .ant-upload-drag-container {
  @apply align-baseline cursor-auto;
}

.upload-zone .ant-upload-drag {
  @apply items-center;
}

.ant-notification-notice-message {
  @apply dark:!text-white;
}

.notice-message-no-padding .ant-notification-notice-message {
  @apply p-0 dark:text-white dark:p-0;
}

.ant-notification-notice {
  @apply dark:rounded-lg;
}

.primary-color {
  @apply text-primary;
}

.ant-upload-drag-container {
  @apply border-none;
}

.file-card {
  @apply text-gray-70 dark:text-gray-5;
}

.ant-dropdown-menu-title-content {
  @apply dark:text-gray-5;
}

.ant-upload-wrapper .ant-upload-drag {
  @apply border-none;

  background-color: transparent;
}

.ant-form-item .ant-form-item-label > label {
  @apply text-xs text-gray-40;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  @apply content-none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  @apply text-primary visible;

  content: "*";
}

.ant-btn,
.ant-modal-confirm .ant-modal-confirm-btns {
  @apply font-semibold;
}

.ant-btn-text {
  @apply font-semibold;
}

.ant-btn-text,
.ant-btn.ant-btn-background-ghost {
  @apply font-normal;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  @apply rounded-none;
}

.ant-dropdown .ant-dropdown-menu {
  @apply px-0;
}

.ant-upload-wrapper .ant-upload-disabled {
  @apply bg-gray-10 dark:bg-gray-100;
}

.ant-modal .ant-modal-content {
  @apply p-0;
}

.ant-btn-primary,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):focus,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  @apply bg-primary text-white shadow-none outline-none;
}

.ant-input-group .ant-input-group-addon:last-child {
  @apply bg-gray-10;
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  @apply dark:text-white dark:bg-gray-80;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  @apply dark:bg-gray-70;
}

.ant-popconfirm-inner-content {
  white-space: nowrap;
}
