@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

.ag-grid {
  --ag-input-component-height: 65px;
  --ag-input-component-padding: 16px;
  --ag-range-selection-border-color: var(--color-primary-default);
  --ag-alpine-active-color: var(--color-primary-default);
  --ag-input-focus-border-color: var(--color-primary-default);

  .ag-root-wrapper {
    @apply border-0;
  }

  .ag-row {
    @apply border-t-0 border-r-0 border-l-0;
  }
}

.ag-theme-alpine {
  --ag-header-background-color: var(--color-gray-10);
  --ag-border-color: var(--color-gray-20);
  --ag-selected-row-background-color: var(--color-primary-30);
  --ag-row-hover-color: var(--color-gray-10);
  --ag-row-border-color: var(--color-gray-20);
  --ag-odd-row-background-color: var(--color-white);
  --ag-header-foreground-color: var(--color-gray-70);
  --ag-data-color: var(--color-gray-70);
}

.ag-body-horizontal-scroll {
  @apply bg-gray-10;
}

.ag-icon-eye {
  background: transparent url("../assets/images/eye.svg") center/contain no-repeat;
  color: transparent;
}

.ag-icon-cut {
  background: transparent url("../assets/images/delete.svg") center/contain no-repeat;
  color: transparent;
}

.ag-header-icon {
  display: none;
}

.ag-tabs:has(.custom-menu-item) .ag-tabs-header {
  display: none;
}

.ag-tabs:has(.custom-menu-item) {
  margin-top: 28px;
}

.ag-theme-alpine-dark {
  --ag-header-background-color: var(--color-gray-80);
  --ag-border-color: var(--color-gray-70);
  --ag-selected-row-background-color: var(--color-primary-300);
  --ag-row-hover-color: var(--color-gray-80);
  --ag-row-border-color: var(--color-gray-70);
  --ag-background-color: var(--color-gray-100);
  --ag-odd-row-background-color: var(--color-gray-100);

  .ag-body-horizontal-scroll {
    @apply bg-gray-80;
  }

  &.merge-grid {
    --ag-border-color: var(--color-gray-60);
    --ag-row-border-color: var(--color-gray-60);
    --ag-background-color: var(--color-gray-70);
    --ag-odd-row-background-color: var(--color-gray-70);
  }
}

.ag-row-no-animation {
  .ag-row {
    @apply transition-none;
  }
}

.ag-root {
  .ag-cell {
    @apply inline-flex items-center p-2;
  }

  .ag-react-container {
    @apply flex flex-auto;
  }

  .ag-cell-normal-height {
    /* 2px is a border offset */
    height: calc(var(--ag-input-component-height) - 2px);
    padding: var(--ag-input-component-padding);
    border-radius: 0;

    &.ag-cell-inline-editing:not(.structure-cell) {
      padding: 0;

      input[class^="ag-"][type="text"],
      input {
        height: var(--ag-input-component-height);
        padding: var(--ag-input-component-padding);
        border: 0;
      }
    }
  }

  .cell-loader-placeholder::before {
    @apply bg-gray-10 dark:bg-gray-70;

    display: block;
    content: "";
    height: 1rem;
    width: 100%;
  }
}
